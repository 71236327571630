import React from 'react';
import { imageUrlFor } from '../lib/image-url';
import { buildImageObj } from '../lib/helpers';
import styles from './image-grid.module.css';

function ImageGrid({ images }) {
  return (
    <div className={styles.root}>
      <div className={styles.grid1}>
        <img
          src={imageUrlFor(buildImageObj(images[0]))
            .height(600)
            .width(600)}
        />
      </div>
      <div className={styles.grid4}>
        {images &&
          images.slice(1).map(im => (
            <img
              src={imageUrlFor(buildImageObj(im))
                .width(400)
                .height(400)}
            />
          ))}
      </div>
    </div>
  );
}
ImageGrid.defaultProps = {
  images: []
};
export default ImageGrid;
