import { Link } from 'gatsby';
import React from 'react';

import styles from './link-block.module.css';

function LinkBlock({ text, path }) {
  return (
    <Link className={styles.root} to={path}>
      {text}
    </Link>
  );
}
LinkBlock.defaultProps = {
  text: '',
  link: ''
};
export default LinkBlock;
