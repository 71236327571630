import React from 'react'
import {StaticQuery, graphql} from 'gatsby'

import styles from './store-hours.module.css'


//const storeHours = (data || {}).storeHours
//? mapEdgesToNodes(data.storeHours)[0] : []

function StoreHours () {
  return (
    <StaticQuery
        query={storeHoursQuery}
        render={data => (
            <div className={styles.root}>
                <span className={styles.header}>Åpningstider</span>
                <div className={styles.hours}>
                  <div className={styles.day}>
                        <span className={styles.nameOfDay}>Mandag</span>
                        <span className={styles.valueOfDay}>{data.storeHours.edges[0].node.monday}</span>
                    </div>
                    <div className={styles.day}>
                        <span className={styles.nameOfDay}>Tirsdag</span>
                        <span className={styles.valueOfDay}>{data.storeHours.edges[0].node.tuesday}</span>
                    </div>
                    <div className={styles.day}>
                        <span className={styles.nameOfDay}>Onsdag</span>
                        <span className={styles.valueOfDay}>{data.storeHours.edges[0].node.wednesday}</span>
                    </div>
                    <div className={styles.day}>
                        <span className={styles.nameOfDay}>Torsdag</span>
                        <span className={styles.valueOfDay}>{data.storeHours.edges[0].node.thursday}</span>
                    </div>
                    <div className={styles.day}>
                        <span className={styles.nameOfDay}>Fredag</span>
                        <span className={styles.valueOfDay}>{data.storeHours.edges[0].node.friday}</span>
                    </div>
                    <div className={styles.day}>
                        <span className={styles.nameOfDay}>Lørdag</span>
                        <span className={styles.valueOfDay}>{data.storeHours.edges[0].node.saturday}</span>
                    </div>
                </div>
                
            </div>
        )} 
    />
  )
}

export default StoreHours


const storeHoursQuery = graphql`
  query DefaultStoreHoursQuery {
    storeHours: allSanityStoreHours(limit: 1) {
        edges {
          node {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
          }
        }
      }
  }
`