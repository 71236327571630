import {Link} from 'gatsby'
import React from 'react'
import NewsPreview from './news-preview'
import UnderlinedHeader from './underlined-header'
import styles from './news-preview-grid.module.css'
import { lengthIsOdd } from '../lib/helpers'


function NewsPreviewGrid (props) {
  return (
    <div className={styles.root}>
      {props.title && <UnderlinedHeader text={props.title} />}
      <ul className={`${styles.grid} ${lengthIsOdd(props.nodes) ? styles.odd : ''}`}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <NewsPreview {...node} />
            </li>
          ))}
      </ul>
    </div>
  )
}

NewsPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default NewsPreviewGrid
