import {Link} from 'gatsby'
import React from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'

import styles from './news-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function NewsPreview (props) {
  return (
    <Link className={styles.root} to={`/nyheter/${props.slug.current}`}>
      <div className={styles.leadMediaThumb}>
          
          {props.images &&
            props.images.map(im => (
                <img
                src={imageUrlFor(buildImageObj(im))
                .width(1024)
                .height(Math.floor((9 / 16) * 1024))
                .url()}
                
            />
          ))}
          <h3 className={cn(responsiveTitle3, styles.title)}>{props.title} →</h3>


      </div>
      {props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )}
    </Link>
  )
}

export default NewsPreview
