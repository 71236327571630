import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes, filterOutExpiredDocs, filterOutNotPublishedDocs } from '../lib/helpers';

import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import NewsPreviewGrid from '../components/news-preview-grid';
import LinkBlock from '../components/link-block';
import UnderlinedHeader from '../components/underlined-header';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import ImageGrid from '../components/image-grid.js';
import StoreHours from '../components/store-hours';
export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      subtitle
      description
      keywords
    }
    imageSets: allSanityImageSet(limit: 5, filter: { imageSetName: { eq: "Forside" } }) {
      edges {
        node {
          id
          imageSetName
          images {
            _key
            _type
            asset {
              _id
            }
          }
        }
      }
    }
    contentPages: allSanityContentPage(filter: { showLinkToPage: { eq: true } }) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    promotions: allSanityPromotion(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          title
          validTo
          validFrom
          slug {
            current
          }
          images {
            _key
            _type
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const galleryNodes = (data || {}).imageSets ? mapEdgesToNodes(data.imageSets) : [];

  const promotions = (data || {}).promotions
    ? mapEdgesToNodes(data.promotions)
        .filter(node => filterOutExpiredDocs(node))
        .filter(node => filterOutNotPublishedDocs(node))
    : [];

  console.log(promotions);

  const contentPages = (data || {}).contentPages ? mapEdgesToNodes(data.contentPages) : [];

  const gridImages = galleryNodes[0].images.slice(0, 5);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <ImageGrid images={gridImages} />
        <h1>{site.subtitle}</h1>

        <div class="row wrap gap">
          <LinkBlock path="/produkter" text="Alle produkter" />
          {contentPages && contentPages.map(node => <LinkBlock path={node.slug.current} text={node.title} />)}
        </div>

        <UnderlinedHeader text="Besøk oss" />
        <div>
          <p>
            Guntor Interiør holder til sentralt i egne lokaler like ved Herkules kjøpesenter i Skien. I vår forretning
            finner du det meste du trenger for å innrede ditt hus, leilighet, hytte, samt et godt utvalg av gardiner og
            gaveartikler.
          </p>
          <StoreHours />
        </div>
        {promotions.length > 0 && (
          <NewsPreviewGrid title="Nyheter og kampanjer" nodes={promotions} browseMoreHref="/archive/" />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
